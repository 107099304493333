import { getUserId } from "../constants/function";

const initialState = {
  index: -1,
  songs: [],
  pos: 0,
  track: null,
  timeStamp: null,
  vol: 5,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "START_SONG_STREAM":
      const userId = getUserId();
      const song = action.payload.songs.filter(
        (s) =>
          s?.uploader?._id === userId ||
          (s?.uploader?._id !== userId &&
            s.private &&
            s?.uploader?.isSubscribed) ||
          (s?.uploader?._id !== userId && !s.private)
      );
      const volume =
        action?.payload && action?.payload?.vol >= 0
          ? action.payload.vol
          : state.vol;
      return {
        ...state,
        index: action.payload.index,
        songs: [...song],
        pos: action.payload.pos,
        track: action.payload.track,
        timeStamp: new Date().getTime(),
        vol: volume,
      };
    case "END_SONG_STREAM":
      return {
        ...state,
        index: -1,
        songs: [],
        pos: 0,
        track: null,
      };
    default:
      return state;
  }
};
